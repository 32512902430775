import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There’s a wide range of use cases for certificates `}<em parentName="p">{`inside`}</em>{` containers, depending on what you're running.
Since you can run any application/service inside a container, asking why a container might need a certificate is roughly equivalent to asking why an application/service might need a certificate.
Here are a few examples. `}</p>
    <ul>
      <li parentName="ul">{`A container running Redis or Postgres may need a server TLS certificate.`}</li>
      <li parentName="ul">{`A container running a program that connects to Redis or Postgres using mTLS might need a client TLS certificate`}</li>
      <li parentName="ul">{`You may want to enable service-to-service mTLS everywhere`}</li>
    </ul>
    <p>{`There are two primary patterns used to get certificates to containers`}</p>
    <p><strong parentName="p">{`1. A `}<a parentName="strong" {...{
          "href": "http://kubernetes.io/tls"
        }}><inlineCode parentName="a">{`kubernetes.io/tls`}</inlineCode></a>{` Secret`}</strong></p>
    <p>{`A `}<strong parentName="p"><inlineCode parentName="strong">{`kubernetes.io/tls`}</inlineCode>{` Secret`}</strong>{` is a Kubernetes object type designed to hold an X.509 certificate (tls.crt) and private key (tls.key) for use with TLS. Like other Secrets, TLS Secrets can be mounted into pods, read/written by clients with appropriate access, and can be referenced by some other resources.`}</p>
    <p><strong parentName="p">{`2. Deliver the certificate directly to the container.`}</strong>{` `}</p>
    <p>{`There's a few ways to deliver a certificate directly to a container in Kubernetes. We created `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/autocert"
      }}>{`Autocert`}</a>{` to make it easy to use mutual TLS to improve security within a cluster and to secure communication into, out of, and between Kubernetes clusters. `}</p>
    <p>{`Autocert is a kubernetes add-on that automatically injects TLS/HTTPS certificates into your containers.
To get a certificate, simply annotate your pods with a name.
An X.509 (TLS/HTTPS) certificate is automatically created and mounted at `}<inlineCode parentName="p">{`/var/run/autocert.step.sm/`}</inlineCode>{` along with a corresponding private key and root certificate (everything you need for mTLS).`}</p>
    <h3>{`Our Recommendation`}</h3>
    <p>{`When you want to get a certificate to a container in Kubernetes, you must first ask:`}</p>
    <p><strong parentName="p">{`Do you trust the backing datastore you're using for Kubernetes (often this is `}<inlineCode parentName="strong">{`etcd`}</inlineCode>{`)?`}</strong>{` `}</p>
    <p>{`This datastore is used for Kubernetes TLS Secrets and is where the certificate and private keys are stored.
Depending on how your datastore is configured, the data may or may not be encrypted. `}</p>
    <p>{`Here are two common answers to this question and what we recommend:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`I trust my backing datastore and want to use TLS secrets`}</strong>{` - we recommend using Smallstep Certificate Manager in combination with step-issuer and the `}<a parentName="li" {...{
          "href": "https://cert-manager.io/docs/"
        }}>{`Kubernetes cert-manager`}</a>{` utility. `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/kubernetes-step-issuer"
        }}>{`You can find the detailed instructions here.`}</a></li>
      <li parentName="ol"><strong parentName="li">{`I want to deliver certs directly to the container`}</strong>{` - we recommend using Smallstep Certificate Manager in combination with Autocert. `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/kubernetes-autocert"
        }}>{`You can find detailed instructions here.`}</a>{` `}</li>
    </ol>
    <Alert severity="info" mdxType="Alert">
  <div>
This content is designed to help the reader configure Kubernetes to use private certificates. If you are looking to enable publicly-trusted certificates to connect your cluster to the internet, we recommend using Let’s Encrypt with the <a href="https://cert-manager.io/docs/">Kubernetes cert-manager</a> project.
  </div>
    </Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      